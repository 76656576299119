import { Component, Vue, Watch } from "vue-property-decorator";
import { IError } from "@/interfaces/IError";

@Component
export default class ToastedMixin extends Vue {
  @Watch("error")
  public errorWatcher(val: IError): void {
    if (val) {
      //this.$toasted.error(val.globalErrors[0]);
    }
  }
}
