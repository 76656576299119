




























































import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ToastedMixin from "@/mixins/ToastedMixin";
import FscCard from "@/components/Card/FscCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";

const FurtherEducationConfigModule = namespace("bkf-config");

@Component({
  components: { FscCard, FormCard, AbortButton, SaveButton }
})
export default class BKFConfigs extends mixins(ToastedMixin, CompanyMixin) {
  public name = "BKFConfigs.vue";

  private recognitionAuthority = null;
  private azOfRecognition = null;
  private monitoringAuthority = null;

  @FurtherEducationConfigModule.Action("findAll")
  public furtherEducationConfigFindAll: any;

  @FurtherEducationConfigModule.Action("update")
  public furtherEducationConfigsUpdate: any;

  @FurtherEducationConfigModule.Getter("getDataList")
  public BKFConfigs: any;

  @FurtherEducationConfigModule.Getter("getSuccess")
  public success: any;

  @FurtherEducationConfigModule.Getter("getError")
  public error: any;

  @FurtherEducationConfigModule.Getter("getIsLoading")
  public isLoading: any;

  public mounted(): void {
    this.furtherEducationConfigFindAll({
      resource: "bkf-config/current"
    });
  }

  @Watch("BKFConfigs")
  private onFurtherEducationConfigsChange(data: any): void {
    if (data) {
      this.recognitionAuthority = data.recognitionAuthority;
      this.azOfRecognition = data.azOfRecognition;
      this.monitoringAuthority = data.monitoringAuthority;
    }

  }

  private async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    const data = {
      recognitionAuthority: this.recognitionAuthority,
      azOfRecognition: this.azOfRecognition,
      monitoringAuthority: this.monitoringAuthority
    };

    await this.furtherEducationConfigsUpdate({
      id: "current",
      data: data,
      resource: "bkf-config",
    });

  }
}
