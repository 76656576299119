var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100 pl-20 ml-20",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"pb-3 d-flex justify-content-end"},[_c('abort-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":""},on:{"click":_vm.onAbort}}),_c('save-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.putLoading},on:{"click":_vm.onSubmit}})],1)]},proxy:true}])},[_c('b-row',{staticClass:"py-3"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(" "+_vm._s(_vm.$t("general.test_organization"))+" ")])])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("general.check_point"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.organization.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}})}}])})],1)],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("general.contact_person")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.contactPerson),callback:function ($$v) {_vm.$set(_vm.organization, "contactPerson", $$v)},expression:"organization.contactPerson"}})],1),_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.email"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.organization.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}})}}])})],1)],1),_c('b-row',{staticClass:"pb-3 pt-5"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(_vm._s(_vm.$t("students.address_data")))])])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.city"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.organization.location},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.location),callback:function ($$v) {_vm.$set(_vm.organization, "location", $$v)},expression:"organization.location"}})}}])})],1),_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.post_code"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.organization.postalCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.postalCode),callback:function ($$v) {_vm.$set(_vm.organization, "postalCode", $$v)},expression:"organization.postalCode"}})}}])})],1),_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.street_no"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.organization.address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.address),callback:function ($$v) {_vm.$set(_vm.organization, "address", $$v)},expression:"organization.address"}})}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.telephone")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.phone),callback:function ($$v) {_vm.$set(_vm.organization, "phone", $$v)},expression:"organization.phone"}})],1)],1),_c('b-row',{staticClass:"pb-3 pt-5"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(_vm._s(_vm.$t("students.payment_data")))])])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$tc("calendar.other", 1))+"/"+_vm._s(_vm.$t("general.info"))+" ")]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.organization.info),callback:function ($$v) {_vm.$set(_vm.organization, "info", $$v)},expression:"organization.info"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }