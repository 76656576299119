




































































































































































































import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import { getFile } from "@/utils/File";
import { downloadFile } from "@/utils/File";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";
import { v4 as uuidv4 } from "uuid";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";
import Validation from "@/components/Validation.vue";
import { urlRegexStr } from "@/constants/UrlRegex";

const StudentModule = namespace("student");
const DrivingSchoolModule = namespace("driving-school");

@Component({
  components: {
    FormCard,
    AbortButton,
    SaveButton,
    FscCard,
    Validation,
  },
})
export default class SignatureConfiguration extends mixins(CompanyMixin) {
  public name = "SignatureConfiguration";

  @DrivingSchoolModule.Action("uploadPdfDocument")
  public uploadPdfDocument: any;

  @DrivingSchoolModule.Getter("getPdfSuccess")
  public pdfSuccess: any;

  @DrivingSchoolModule.Action("uploadPngDocument")
  public uploadPngDocument: any;

  @DrivingSchoolModule.Getter("getPngSuccess")
  public pngSuccess: any;

  @DrivingSchoolModule.Action("uploadLogoDocument")
  public uploadLogoDocument: any;

  @DrivingSchoolModule.Getter("getLogoSuccess")
  public logoSuccess: any;

  @DrivingSchoolModule.Getter("getIsLoading")
  public isLoading: any;

  @DrivingSchoolModule.Action("findDrivingSchoolLetterHead")
  public findLetterHead: any;

  @DrivingSchoolModule.Getter("getPdfItem")
  public currentLetterHead: any;

  @DrivingSchoolModule.Getter("getPdfMimeType")
  public pdfMimeType: any;

  @DrivingSchoolModule.Action("findDrivingSchoolSignature")
  public findSignature: any;

  @DrivingSchoolModule.Getter("getPngItem")
  public currentSignature: any;

  @DrivingSchoolModule.Getter("getPngMimeType")
  public pngMimeType: any;

  @DrivingSchoolModule.Action("findDrivingSchoolLogo")
  public findLogo: any;

  @DrivingSchoolModule.Getter("getLogoItem")
  public currentLogo: any;

  @DrivingSchoolModule.Getter("getLogoMimeType")
  public logoMimeType: any;

  @DrivingSchoolModule.Getter("getError")
  public error: any;

  @DrivingSchoolModule.Action("fetchAllSignatureStatuses")
  public getSignatureStatusesAction: any;

  @DrivingSchoolModule.Getter("getSignatureStatuses")
  public signatureStatuses: any;

  @DrivingSchoolModule.Action("findQRCodeUrl")
  public findQRCodeUrlAction: any;

  @DrivingSchoolModule.Getter("getQRCodeUrl")
  public qrCodeUrl: any;

  @DrivingSchoolModule.Action("findDriveBuzzOnboardingLinks")
  public findDriveBuzzOnboardingLinksAction: any;

  @DrivingSchoolModule.Getter("getDriveBuzzOnboardingLinks")
  public getOnboardingLinks: any;

  @DrivingSchoolModule.Action("create")
  public createOnboardingData: any;
  @DrivingSchoolModule.Action("update")
  public updateOnbordingData: any;
  private invalidLinks: any = {};

  public validate({ title, href }: any) {
    return {
      invalidTitle: !title && !!href == true,
      invalidUrl: !!title == true && !!href == false,
      invalidUrlFormat: !!href == false ? false : !new RegExp(urlRegexStr).test(href),
    };
  }

  public validateModel(model: string) {
    switch (model) {
      case "onboardingLinks": {
        this.invalidLinks[model] = false;
        const result: any = this.drivingSchoolOnboardingData.onboardingLinks.map((link: any) => {
          let validity = this.validate(link);
          return validity.invalidTitle || validity.invalidUrl || validity.invalidUrlFormat;
        });

        this.invalidLinks[model] = result.reduce((accum: any, item: any) => accum || item, false);
        return;
      }
      case "socialLinks": {
        this.invalidLinks[model] = false;
        const result: any = this.drivingSchoolOnboardingData.socialLinks.map((link: any) => {
          let validity = this.validate(link).invalidUrlFormat;
          return validity;
        });
        this.invalidLinks[model] = result.reduce((accum: any, item: any) => accum || item, false);
        return;
      }
    }
  }

  public filePdf?: File;
  public filePng?: File;
  public fileLogo?: File;
  public documentView = false;
  private sourcePdf = "";
  private sourcePng = "";
  private sourceLogo = "";
  private sourceDriveBuzzRegistrationQrCode = "";
  private pdfSelected = false;
  private pngSelected = false;
  private logoSelected = false;
  private fileSizeInvalid = { pdf: false, png: false, logo: false };

  public drivingSchoolOnboardingData: any = {
    onboardingLinks: [
      { title: "", href: "" },
      { title: "", href: "" },
      { title: "", href: "" },
    ],
    socialLinks: [
      { title: "Google", href: "" },
      { title: "YouTube", href: "" },
      { title: "Instagram", href: "" },
      { title: "Facebook", href: "" },
    ],
  };
  private requestTypeUpdate = true;
  private linksValidationInvalid = false;

  public async mounted() {
    this.getSignatureStatusesAction();
    this.findQRCodeUrlAction();
    await this.findDriveBuzzOnboardingLinksAction();
    if (this.getOnboardingLinks && Object.keys(this.getOnboardingLinks).length > 0) {
      this.requestTypeUpdate = true;
      this.drivingSchoolOnboardingData = this.getOnboardingLinks;
    } else {
      this.requestTypeUpdate = false;
    }
  }

  public onSelectedPdfFileBeforeSubmit(event: any): void {
    this.pdfSelected = true;
    this.filePdf = event.target.files[0];
    this.fileSizeInvalid.pdf = !checkFileSizeBeforeUpload(this.filePdf, UploadConfiguration.SIZE_CONFIGURATION);
    this.sourcePdf = URL.createObjectURL(event.target.files[0]);
  }

  public onSelectedPngFileBeforeSubmit(event: any): void {
    this.pngSelected = true;
    this.filePng = event.target.files[0];
    this.fileSizeInvalid.png = !checkFileSizeBeforeUpload(this.filePng, UploadConfiguration.SIZE_CONFIGURATION);
    this.sourcePng = URL.createObjectURL(event.target.files[0]);
  }

  public onSelectedLogoFileBeforeSubmit(event: any): void {
    this.logoSelected = true;
    this.fileLogo = event.target.files[0];
    this.fileSizeInvalid.logo = !checkFileSizeBeforeUpload(this.fileLogo, UploadConfiguration.SIZE_CONFIGURATION);
    this.sourceLogo = URL.createObjectURL(event.target.files[0]);
  }

  public onPdfUploadSubmit(file: any): void {
    let formData = new FormData();
    formData.append("file", file);
    this.uploadPdfDocument({
      resource: "driving-school-documents/letterhead/current",
      data: formData,
    });
  }

  public onPngUploadSubmit(file: any): void {
    let formData = new FormData();
    formData.append("file", file);
    this.uploadPngDocument({
      resource: "driving-school-documents/signature/current",
      data: formData,
    });
  }

  public onLogoUploadSubmit(file: any): void {
    let formData = new FormData();
    formData.append("file", file);
    this.uploadLogoDocument({
      resource: "driving-school-documents/logo/current",
      data: formData,
    });
  }

  public async onSubmit(): Promise<void> {
    const invalidFileSize = this.fileSizeInvalid.png || this.fileSizeInvalid.pdf || this.fileSizeInvalid.logo;
    if (invalidFileSize) return;

    this.validateModel("onboardingLinks");
    this.validateModel("socialLinks");
    if (this.invalidLinks.onboardingLinks || this.invalidLinks.socialLinks) return;

    if (this.pdfSelected) await this.onPdfUploadSubmit(this.filePdf);
    if (this.pngSelected) await this.onPngUploadSubmit(this.filePng);
    if (this.logoSelected) await this.onLogoUploadSubmit(this.fileLogo);
    if (this.requestTypeUpdate) {
      await this.updateOnbordingData({
        resource: "driving-schools/current/drive-buzz-onboarding-links",
        data: this.drivingSchoolOnboardingData,
      });
    } else {
      await this.createOnboardingData({
        resource: "driving-schools/current/drive-buzz-onboarding-links",
        data: this.drivingSchoolOnboardingData,
      });
    }
    this.pdfSelected = false;
    this.pngSelected = false;
    this.logoSelected = false;
    await this.getSignatureStatusesAction();
  }

  public onAbort(): void {
    this.$router.go(0);
  }

  public onShowLetterHead(): void {
    this.findLetterHead().then(() => {
      this.$bvModal.show("pdfPopover");
    });
  }

  public onShowSignature(): void {
    this.findSignature();
    this.$bvModal.show("pngPopover");
  }

  public onShowLogo(): void {
    this.findLogo();
    this.$bvModal.show("logoPopover");
  }

  public getDriveBuzzRegistrationQrCode(): void {
    getFile({ method: "GET", url: "/driving-schools/current/drive-buzz-registration-qr-code" }, false).then((value) => {
      this.sourceDriveBuzzRegistrationQrCode = value;
      this.$bvModal.show(this.qrCodePopoverId);
    });
  }

  public downloadDriveBuzzRegistrationQrCodeImage(): void {
    downloadFile({ method: "GET", url: "/driving-schools/current/drive-buzz-registration-qr-code" }, "qrCode.png");
  }

  public downloadDriveBuzzRegistrationQrCodePDF(): void {
    getFile({ method: "GET", url: "/driving-schools/current/drive-buzz-registration-qr-code/pdf" }, true);
  }

  public get qrCodePopoverId() {
    return uuidv4();
  }

  @Watch("pdfSuccess", { deep: true, immediate: true })
  public onPdfUploadSuccess(success: any): void {
    if (success) {
      this.findLetterHead();
      this.$toasted.success("Letterhead uploaded!");
    }
  }

  @Watch("pngSuccess", { deep: true, immediate: true })
  public onPngUploadSuccess(success: any): void {
    if (success) {
      this.findSignature();
      this.$toasted.success("Signature uploaded!");
    }
  }

  @Watch("logoSuccess", { deep: true, immediate: true })
  public onLogoUploadSuccess(success: any): void {
    if (success) {
      this.findLogo();
      this.$toasted.success("Logo uploaded!");
    }
  }

  @Watch("currentLetterHead")
  public onDrivingSchoolLetterHeadChange(blob: ArrayBuffer) {
    if (blob) {
      //@ts-ignore
      const blobBase64 = new Buffer(blob, "binary").toString("base64");
      this.sourcePdf = "data:" + this.pdfMimeType + ";base64," + blobBase64;
    }
  }

  @Watch("currentSignature")
  public onDrivingSchoolSignatureChange(blob: ArrayBuffer) {
    if (blob) {
      //@ts-ignore
      const blobBase64 = new Buffer(blob, "binary").toString("base64");
      this.sourcePng = "data:" + this.pngMimeType + ";base64," + blobBase64;
    }
  }

  @Watch("currentLogo")
  public onDrivingSchoolLogoChange(blob: ArrayBuffer) {
    if (blob) {
      //@ts-ignore
      const blobBase64 = new Buffer(blob, "binary").toString("base64");
      this.sourceLogo = "data:" + this.logoMimeType + ";base64," + blobBase64;
    }
  }

  @Watch("error")
  public onUploadError(error: any): void {
    if (error) {
      this.$toasted.error(error);
    }
  }
}
