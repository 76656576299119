


















































































































import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ToastedMixin from "@/mixins/ToastedMixin";
import FscCard from "@/components/Card/FscCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";

const TextsConfigModule = namespace("text-config");

@Component({
  components: { FscCard, FormCard, AbortButton, SaveButton },
})
export default class ConfigurationsTexts extends mixins(ToastedMixin, CompanyMixin) {
  public name = "ConfigurationsTexts";
  private gdprTextUrl = null;
  private termsAndConditionsUrl = null;
  private printoutBfkCertificate = null;
  private paymentRequestFootnote = null;
  private finalInvoiceFootnote = null;
  private paymentRequestFootnoteCostBearer = null;
  private finalInvoiceFootnoteCostBearer = null;

  @TextsConfigModule.Action("findAll")
  public textConfigsFindAll: any;

  @TextsConfigModule.Action("update")
  public textConfigsUpdate: any;

  @TextsConfigModule.Getter("getDataList")
  public textConfigs: any;

  @TextsConfigModule.Getter("getSuccess")
  public success: any;

  @TextsConfigModule.Getter("getError")
  public error: any;

  @TextsConfigModule.Getter("getIsLoading")
  public isLoading: any;

  public mounted(): void {
    this.textConfigsFindAll({
      resource: "texts-config/current",
    });
  }

  @Watch("textConfigs")
  private onTextConfigsChange(data: any): void {
    if (data) {
      this.gdprTextUrl = data.gdprTextUrl;
      this.termsAndConditionsUrl = data.termsAndConditionsUrl;
      this.printoutBfkCertificate = data.printoutBfkCertificate;
      this.paymentRequestFootnote = data.paymentRequestFootnote;
      this.finalInvoiceFootnote = data.finalInvoiceFootnote;
      this.paymentRequestFootnoteCostBearer = data.paymentRequestFootnoteCostBearer;
      this.finalInvoiceFootnoteCostBearer = data.finalInvoiceFootnoteCostBearer;
    }
  }

  private async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    const data = {
      gdprTextUrl: this.gdprTextUrl,
      termsAndConditionsUrl: this.termsAndConditionsUrl,
      printoutBfkCertificate: this.printoutBfkCertificate,
      paymentRequestFootnote: this.paymentRequestFootnote,
      finalInvoiceFootnote: this.finalInvoiceFootnote,
      paymentRequestFootnoteCostBearer: this.paymentRequestFootnoteCostBearer,
      finalInvoiceFootnoteCostBearer: this.finalInvoiceFootnoteCostBearer,
    };
    await this.textConfigsUpdate({
      id: "current",
      data: { id: 0, ...data },
      resource: "texts-config",
    });
  }
}
