






























import { Component } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import WarningsRequestMixin from "@/mixins/Request/WarningsRequestMixin";

@Component({
  components: {
    AbortButton,
    SaveButton,
    FscSimpleCard,
  },
})
export default class DashboardWarnings extends mixins(WarningsRequestMixin) {
  public name = "DashboardWarnings";

  public dashboardWarnings: any = [];
  public key = 0;

  public async mounted(): Promise<void> {
    await this.findAllWarnings();
    this.dashboardWarnings = this.warningsList;
  }

  public async onAbort() {
    await this.findAllWarnings();
    this.dashboardWarnings = this.warningsList;
  }

  public async onSubmit() {
    await this.updateWarnings(this.dashboardWarnings);
    if (this.warningsSuccess) {
      this.$toasted.success("Erfolgreich aktualisiert!");
      await this.findAllWarnings();
      this.dashboardWarnings = this.warningsList;
    }
  }

  public hideBirthdayType(warning: any) {
    if (warning?.studentNotificationType === "BIRTHDAY") {
      return false;
    }
    return true;
  }
}
