























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";

@Component({
  components: {
    FscSimpleCard,
    SaveButton,
    AbortButton,
    Validation,
    FscMultiselect,
  },
})
export default class TestingOrganizationForm extends mixins(CompanyMixin) {
  public name = "TestingOrganizationForm";

  @Prop()
  testingOrganization!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public organization = {
    name: "",
    contactPerson: "",
    email: "",
    postalCode: "",
    location: "",
    address: "",
    phone: "",
    info: "",
  };

  public mounted(): void {
    this.organization = this.testingOrganization;
  }

  public onAbort(): void {
    this.$emit("on-abort");
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const testOrg = {
      name: this.organization.name,
      contactPerson: this.organization.contactPerson,
      email: this.organization.email,
      postalCode: this.organization.postalCode,
      location: this.organization.location,
      address: this.organization.address,
      phone: this.organization.phone,
      info: this.organization.info,
    };
    this.$emit("on-submit", testOrg);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      organization: {
        name: { required },
        email: { required, email },
        postalCode: { required },
        location: { required },
        address: { required },
      },
    };
  }

  @Watch("testingOrganization", { immediate: true, deep: true })
  public onTestingOrganizationChange(testOrg: any): void {
    if (testOrg) {
      this.organization = testOrg;
    }
  }
}
